import "keen-slider/keen-slider.min.css";
import { useKeenSlider } from "keen-slider/react";
import Image from "next/image";
import React from "react";

export interface LogoObject {
    url: string;
    alt: string;
    width: number;
    height: number;
}

const clientLogosData: LogoObject[] = [
    { url: "https://assets.gallabox.com/gb-home/new-landing/client-logos/pickyourtrail.svg", alt: "pickyourtrail", width: 180, height: 60 },
    { url: "https://assets.gallabox.com/gb-home/new-landing/client-logos/TATA1mg.png", alt: "Tata1mg", width: 134, height: 48 },
    { url: "https://assets.gallabox.com/gb-home/new-landing/client-logos/thrillophilia.png", alt: "thrillophilia", width: 134, height: 48 },
    { url: "https://assets.gallabox.com/gb-home/new-landing/client-logos/biotique.png", alt: "biotique", width: 134, height: 48 },
    { url: "https://assets.gallabox.com/gb-home/new-landing/client-logos/footprint.png", alt: "footprint", width: 178, height: 40 },
    { url: "https://assets.gallabox.com/gb-home/new-landing/client-logos/QIC.svg", alt: "QIC", width: 109, height: 90 },
    { url: "https://assets.gallabox.com/gb-home/new-landing/client-logos/shiprocket.svg", alt: "shiprocket", width: 172, height: 38 },
    { url: "https://assets.gallabox.com/gb-home/new-landing/client-logos/vakil-search.svg", alt: "vakil-search", width: 180, height: 60 },
    { url: "https://assets.gallabox.com/gb-home/new-landing/client-logos/shams.png", alt: "shams", width: 109, height: 90 },
    { url: "https://assets.gallabox.com/gb-home/new-landing/client-logos/aecc.png", alt: "aecc", width: 200, height: 31 },
    { url: "https://assets.gallabox.com/gb-home/new-landing/client-logos/apollo_hospitals.svg", alt: "apollo_hospitals", width: 71, height: 76 },
    { url: "https://assets.gallabox.com/gb-home/new-landing/client-logos/marg.png", alt: "marg", width: 180, height: 51 },
    { url: "https://assets.gallabox.com/gb-home/new-landing/client-logos/pvr.svg", alt: "pvr", width: 109, height: 90 },
    { url: "https://assets.gallabox.com/gb-home/new-landing/client-logos/american_tourister.svg", alt: "american_tourister", width: 134, height: 37 },
];

const ClientLogos: React.FC<{ logos?: LogoObject[] }> = ({ logos }) => {
    const animation = { duration: 10000, easing: (t: any) => t };

    const [sliderRef] = useKeenSlider({
        slides: { perView: 6, spacing: 40 },
        loop: true,
        drag: true,
        created(s) {
            s.moveToIdx(5, true, animation);
        },
        updated(s) {
            s.moveToIdx(s.track.details.abs + 5, true, animation);
        },
        animationEnded(s) {
            s.moveToIdx(s.track.details.abs + 5, true, animation);
        },
    });

    return (
        <>
            <div className="lg:block  hidden">
                <div ref={sliderRef} className="keen-slider sliderparent">
                    {(logos ? logos : clientLogosData).map((e, i) => (
                        <div
                            className={`keen-slider__slide slider-card number-slide${i} flex items-center justify-center`}
                            style={{ minWidth: `${e.width}px !important`, width: `${e.width}px !important`, maxWidth: `${e.width}px !important`, height: "90px" }}
                            key={i}
                        >
                            <div className="flex items-center">
                                <Image width={e.width} height={e.height} src={e.url} alt={e.alt} />
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <div className="lg:hidden px-5 grid grid-cols-2 gap-6 justify-center items-center">
                {(logos ? logos : clientLogosData).slice(0, 8).map((e, i) => (
                    <div key={i} className="flex items-center justify-center border border-solid border-gray-200 px-3 py-2 rounded" style={{ height: "88px" }}>
                        <Image width={e.width} height={58} src={e.url} alt={e.alt} />
                    </div>
                ))}
            </div>
        </>
    );
};

export default ClientLogos;
